<template>
    <headers :block="scrolledDistance>100?true:false"></headers>
    <div class="top-gap"></div>
    <!-- 质押按钮 -->
    <div class="lp max-width">
        <div class="left">
            <h1>
                STAKOS,
                <span>{{ $t('home.title') }}</span>
            </h1>
            <p>{{ $t('home.desc') }}</p>
            <div>
                <button @click="goPage('lp')">
                    <img src="../../static/images/header/lp-s.png" alt="">
                    {{ $t('home.lp') }}
                </button>
                <div>
                    <img src="../../static/images/home/twitter.png" alt="">
                    <img src="../../static/images/home/tg.png" alt="">
                </div>
            </div>
        </div>
        <div class="right">
            <img src="../../static/images/home/lp-right.png" alt="">
        </div>
    </div>
    <!-- 优势 -->
    <div class="advantage max-width">
        <div v-for="item in 3" :key="item">
            <img :src="require('../../static/images/home/advantage' + item + '.png')" alt="">
            <b class="name">{{ $t('home.advantageTitle' + item) }}</b>
            <div>{{ $t('home.advantageDesc' + item) }}</div>
        </div>
    </div>
    <!-- 利益 -->
    <div class="gain">
        <div class="max-width">
            <b class="gain-title">{{ $t('home.gain') }}</b>
            <div>
                <img src="../../static/images/home/gain.png" alt="">
                <div class="right">
                    <div v-for="item in 3" :key="item">
                        <img :src="require('../../static/images/home/gain' + item + '.png')" alt="">
                        <div>
                            <b>{{ $t('home.gainTitle' + item) }}</b>
                            <p>{{ $t('home.gainDesc' + item) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="friend max-width">
        <div>
            <b>{{ $t('home.friendTitle') }}</b>
            <p>{{ $t('home.friendDesc') }}</p>
        </div>
    </div>
    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {computed, reactive, watch, onMounted} from 'vue';
    import Util from "@/utils/common-util";

    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Web3 from "web3";
    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = new useRouter()

    onMounted(() => {
    })

    const tokenList = reactive([
        {name: 'price', num: '0', unit: 'USDT'},
        {name: 'market', num: '0', unit: 'USDT'},
        {name: 'tokenAll', num: '0', unit: 'ATI'},
    ])

    function toURL(url) {
      if (url) {
        window.open(url, '_blank')
      } else {
        ElMessage({
          message: i18n.global.t('alert.soon'),
          type: 'warning'
        })
      }
    }

    function goPage(url){
        console.log(111)
      router.push({
        path: url
      })
    }

    function toWhitePaper() {
      let language = localStorage.getItem('locale') || 'cn' ;
      let url = ''
      if (language === 'cn') {
        url = ''
      }
      toURL(url)
    }

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/index.less" lang="less"></style>
